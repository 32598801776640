import { useState } from "react";
// @mui
import {
    Box,
    Stack,
    List,
    Badge,
    Avatar,
    Tooltip,
    Divider,
    IconButton,
    Typography,
    ListItemText,
    ListItemAvatar,
    ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "@/utils/formatTime";
// components
import Iconify from "@/Components/iconify";
import Scrollbar from "@/Components/scrollbar";
import MenuPopover from "@/Components/menu-popover";
import { IconButtonAnimate } from "@/Components/animate";
import { Link } from "@inertiajs/react";

// ----------------------------------------------------------------------

export default function NotificationsPopover({ notifications }) {
    const [openPopover, setOpenPopover] = useState(null);

    const unreadNotifications = notifications?.filter(
        (notification) => notification.read === 0
    );
    const readNotifications = notifications?.filter(
        (notification) => notification.read === 1
    );
    const unreadCount = unreadNotifications?.length;

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleMarkAllAsRead = () => {};

    // Sort notifications in ascending order by created_at
    const sortedNotifications = notifications
        ? [...notifications]?.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
        : [];

    return (
        <>
            <IconButtonAnimate
                color={openPopover ? "primary" : "default"}
                onClick={handleOpenPopover}
                sx={{ width: 40, height: 40 }}
            >
                <Badge badgeContent={unreadCount} color="primary">
                    <Iconify icon="mdi:bell" />
                </Badge>
            </IconButtonAnimate>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                sx={{ width: 360, p: 0 }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        py: 2,
                        px: 2.5,
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                            Notifications
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >
                            You have {unreadCount} unread messages
                        </Typography>
                    </Box>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
                    <List sx={{ maxHeight: "85vh" }} disablePadding>
                        {sortedNotifications &&
                            sortedNotifications.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                />
                            ))}
                    </List>
                </Scrollbar>
            </MenuPopover>
        </>
    );
}

function NotificationItem({ notification }) {
    const { data, id, read_at, created_at } = notification;
    const { avatar, title } = renderContent(notification);

    const routeName =
        notification?.type && notification?.type === "message_update"
            ? route("messaging.conversation.show", notification.from_user_id)
            : "#";

    return (
        <ListItemButton
            sx={{
                py: 1.5,
                px: 2.5,
                mt: "1px",
                ...(read_at === null && {
                    bgcolor: "action.selected",
                }),
            }}
            LinkComponent={Link}
            href={routeName}
            onClick={() =>
                handleNotification(notification.type, notification.from_user_id)
            }
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
            </ListItemAvatar>

            <ListItemText
                disableTypography
                primary={title}
                secondary={
                    <Stack
                        direction="row"
                        sx={{
                            mt: 0.5,
                            typography: "caption",
                            color: "text.disabled",
                        }}
                    >
                        <Iconify
                            icon="eva:clock-fill"
                            width={16}
                            sx={{ mr: 0.5 }}
                        />
                        <Typography variant="caption">
                            {fToNow(created_at)}
                        </Typography>
                    </Stack>
                }
            />
        </ListItemButton>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const {
        type,
        message,
        subtitle,
        read,
        creator,
        title: dataTitle,
    } = notification;

    const title = (
        <Typography variant="subtitle2">
            <Box
                display="block"
                component="span"
                sx={{ fontWeight: read ? "" : "bold" }}
            >
                {read ? "" : "New"} Message received
            </Box>
            {subtitle && (
                <Box display="block" component="span">
                    {subtitle}
                </Box>
            )}
            <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.secondary" }}
            >
                {message}
            </Typography>
        </Typography>
    );

    const icons = {
        order_placed: "/storage/assets/icons/notification/ic_package.svg",
        order_shipped: "/storage/assets/icons/notification/ic_shipping.svg",
        mail: "/storage/assets/icons/notification/ic_mail.svg",
        message_update: "/storage/assets/icons/notification/ic_chat.svg",
    };

    const avatar = icons[type] ? (
        <img alt={message} src={icons[type]} />
    ) : creator?.profile?.thumbnail ? (
        <img alt={dataTitle} src={creator.profile.thumbnail} />
    ) : (
        route("image", {
            path: "assets/images/default-profile.jpg",
            w: 24,
            h: 24,
            fit: "crop",
        })
    );

    return {
        avatar,
        title,
    };
}
